import React, { useContext } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";

import { CarInsuranceFormContext } from "../../../pages/CarInsurance/CarInsuranceProposal";
import { basicDetailsValidationSchema } from "../../../helper/validationSchema";

function BasicDetails() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(CarInsuranceFormContext);

  const formik = useFormik({
    initialValues: {
      registrationnumber: formData.registrationnumber,
      customername: formData.customername,
      email: formData.email,
      mobilenumber: formData.mobilenumber,
      gender: formData.gender,
      maritalstatus: formData.maritalstatus,
      dob: formData.dob,
      pannumber: formData.pannumber,
      gstnumber: formData.gstnumber,
    },
    validationSchema: basicDetailsValidationSchema,
  });

  const handleProposerDetailSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleProposerDetailSubmit(formik.values);
      }}
      className="flex flex-col space-y-2"
    >
      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">
            Registration Number
          </label>
          <input
            name="registrationnumber"
            required
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 focus:outline-none bg-slate-100"
            placeholder="Enter Number"
            onChange={formik.handleChange}
            value={formik.values.registrationnumber}
          />
          {formik.touched.registrationnumber &&
          formik.errors.registrationnumber ? (
            <div className="text-red-500">
              {formik.errors.registrationnumber}
            </div>
          ) : null}
        </div>
      </div>
      <div className="lg:flex lg:space-x-8 lg:space-y-0 space-y-3">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Customer Name</label>
          <input
            name="customername"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter Name"
            onChange={formik.handleChange}
            value={formik.values.customername}
          />
          {formik.touched.customername && formik.errors.customername ? (
            <div className="text-red-500">{formik.errors.customername}</div>
          ) : null}
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Email</label>
          <input
            name="email"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter Email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className="lg:flex lg:space-x-8 lg:space-y-0 space-y-3">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Mobile Number</label>
          <input
            name="mobilenumber"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter Mobile Number"
            maxLength={10}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              formik.handleChange(e);
            }}
            value={formik.values.mobilenumber}
          />
          {formik.touched.mobilenumber && formik.errors.mobilenumber ? (
            <div className="text-red-500">{formik.errors.mobilenumber}</div>
          ) : null}
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Gender</label>
          <Select
            className="p-1 w-full lg:w-96"
            name="gender"
            required
            options={[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
            ]}
            value={{
              value: formik.values.gender,
              label: formik.values.gender,
            }}
            onChange={(opt) => {
              formik.setFieldValue("gender", opt.value);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#F8FAFC",
              }),
            }}
          />
          {formik.touched.gender && formik.errors.gender ? (
            <div className="text-red-500">{formik.errors.gender}</div>
          ) : null}
        </div>
      </div>
      <div className="lg:flex lg:space-x-8 lg:space-y-0 space-y-3">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Martial Status</label>
          <Select
            className="lg:w-96 w-full p-1"
            placeholder="Select"
            required={true}
            name="maritalstatus"
            options={[
              { value: "Single", label: "Single" },
              { value: "Married", label: "Married" },
            ]}
            value={{
              value: formik.values.maritalstatus,
              label: formik.values.maritalstatus,
            }}
            onChange={(opt) => {
              formik.setFieldValue("maritalstatus", opt.value);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#F8FAFC",
              }),
            }}
          />
          {formik.touched.maritalstatus && formik.errors.maritalstatus ? (
            <div className="text-red-500">{formik.errors.maritalstatus}</div>
          ) : null}
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Date of Birth</label>
          <Datepicker
            popoverDirection="up"
            inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
            primaryColor={"rose"}
            useRange={false}
            asSingle={true}
            value={{
              startDate: formik.values.dob,
              endDate: formik.values.dob,
            }}
            maxDate={new Date()}
            onChange={(selectDate) => {
              formik.setFieldValue("dob", selectDate.startDate);
            }}
          />
        </div>
      </div>
      <div className="lg:flex lg:space-x-8 lg:space-y-0 space-y-3">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">PAN Number</label>
          <input
            name="pannumber"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter PAN"
            onChange={formik.handleChange}
            value={formik.values.pannumber}
          />
          {formik.touched.pannumber && formik.errors.pannumber ? (
            <div className="text-red-500">{formik.errors.pannumber}</div>
          ) : null}
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">
            GST Number (Optional)
          </label>
          <input
            name="gstnumber"
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter GST Number"
            onChange={formik.handleChange}
            value={formik.values.gstnumber}
          />
          {formik.touched.gstnumber && formik.errors.gstnumber ? (
            <div className="text-red-500">{formik.errors.gstnumber}</div>
          ) : null}
        </div>
      </div>

      <div className="flex lg:justify-end justify-center">
        <div className="flex lg:space-x-4 lg:mt-4 mt-4">
          <button
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
}

export default BasicDetails;
