import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

//assets
import CloseIcon from "../../assets/closeIcon.svg";

//helper
import { formatAmountInRupees } from "../../helper/utils";

const PolicyModal = (props) => {
  const { data, closePolicyModal } = props;
  const [showODP, setShowODP] = useState(false);
  const [showTPP, setShowTPP] = useState(false);
  const [showAddOns, setShowAddOns] = useState(false);

  const odpList = [{ id: "basicODPremium", title: "Basic Premium - OD" }];
  const tppList = [{ id: "basicTPPremium", title: "Basic Premium - TP" }];

  const addOns = {
    roadSideAssistance: "Roadside Assistance",
    engineProtection: "Engine Protection",
    tyreProtection: "Tyre Protection",
    rimProtection: "Rim Protection",
    returnToInvoice: "Return To Invoice",
    consumables: "Consumables",
    partsDepreciation: "Parts Depreciation",
    keyAndLockProtect: "Key And Lock Protect",
    personalBelonging: "Personal Belonging",
    personalAccidentCover: "Personal Accident Cover",
    unNamedPassengerCover: "UnNamed Passenger Cover",
    paidDriverLegalLiability: "Paid Driver Legal Liability",
    electricalAccessories: "Electrical Accessories",
    nonElectricalAccessories: "Non Electrical Accessories",
  };

  const calculateODP = () => {
    let total = 0;
    odpList.forEach((item) => {
      let amount = data[item.id];
      const matches = amount?.toString()?.match(/\d+/);
      if (matches) {
        amount = parseInt(matches[0], 10);
        total += amount;
      }
    });
    if (total > 0) return formatAmountInRupees(total);
    else return "NA";
  };

  const calculateAddOns = () => {
    let total = 0;
    data?.AddOnFilteredData?.forEach((item) => {
      let amount = item.value;
      const matches = amount?.toString()?.match(/\d+/);
      if (matches) {
        amount = parseInt(matches[0], 10);
        total += amount;
      }
    });
    if (total > 0) return formatAmountInRupees(total);
    else return "NA";
  };

  return (
    <div className="overflow-hidden">
      <div className="flex justify-between">
        <h2 className="lg:text-2xl text-xl font-medium text-[#9D0004]">
          Policy Details
        </h2>
        <button onClick={closePolicyModal}>
          <img className="lg:w-7 lg:h-7 w-5 h-6" src={CloseIcon} alt="" />
        </button>
      </div>

      <div className="flex flex-col justify-center lg:p-4 p-1">
        <h2 className="font-medium text-lg">Premium Breakup</h2>

        {/* IDV */}
        <div className="flex justify-between font-medium mb-2 mt-2">
          <p className="text-[#00000094]">IDV</p>
          <p>{data.bikeValue}</p>
        </div>

        {/* Own Damage Premium(A) */}
        <div className="p-1">
          <div
            onClick={() => setShowODP(!showODP)}
            className="flex flex-row items-center justify-between cursor-pointer"
          >
            <h3 className="font-medium">
              Own Damage Premium(A)
              <span className="ml-2">
                <FontAwesomeIcon
                  icon={showODP ? faChevronUp : faChevronDown}
                  className="h-4 w-4 text-gray-600"
                />
              </span>
            </h3>

            <p className="font-semibold">{calculateODP()}</p>
          </div>
          <div
            className={`${
              showODP ? "flex" : "hidden"
            } gap-y-[0.5rem] flex-col py-[1rem]`}
          >
            {odpList.map(
              (odp) =>
                data[odp.id] && (
                  <div
                    className={`${
                      data[odp.id] ? "flex" : "hidden"
                    } justify-between ml-4`}
                    key={odp.id}
                  >
                    <p>{odp.title}</p>
                    <p>{formatAmountInRupees(data[odp.id])}</p>
                  </div>
                )
            )}
          </div>
        </div>

        {/* Third Party Premium(B) */}
        <div className="p-1">
          {data.basicTPPremium ? (
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setShowTPP(!showTPP)}
            >
              <div className="flex items-center">
                <h3 className="mb-1 font-medium">
                  Third Party Premium(B)
                  <span className="ml-2">
                    <FontAwesomeIcon
                      icon={showTPP ? faChevronUp : faChevronDown}
                      className="h-4 w-4 text-gray-600"
                    />
                  </span>
                </h3>
              </div>
              <p className="font-semibold">
                {formatAmountInRupees(data.basicTPPremium)}
              </p>
            </div>
          ) : (
            <p></p>
          )}
          <div
            className={`${
              showTPP ? "flex" : "hidden"
            } gap-y-[0.5rem] flex-col py-[1rem]`}
          >
            {tppList.map(
              (tpp) =>
                data[tpp.id] && (
                  <div
                    className={`${
                      data[tpp.id] ? "flex" : "hidden"
                    } justify-between ml-4`}
                    key={tpp.id}
                  >
                    <p>{tpp.title}</p>
                    <p>{formatAmountInRupees(data[tpp.id])}</p>
                  </div>
                )
            )}
          </div>
        </div>

        {/* AddOnFilteredData */}
        <div className="p-1">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setShowAddOns(!showAddOns)}
          >
            <div className="flex items-center gap-x-[2rem]">
              <h3 className="mb-1 font-medium">
                Addon Detail
                <span className="ml-2">
                  <FontAwesomeIcon
                    icon={showAddOns ? faChevronUp : faChevronDown}
                    className="h-4 w-4 text-gray-600"
                  />
                </span>
              </h3>
            </div>
            <p className="font-semibold">{calculateAddOns()}</p>
          </div>
          <div
            className={`${
              showAddOns ? "flex" : "hidden"
            } gap-y-[0.5rem] flex-col py-[1rem]`}
          >
            {data?.AddOnFilteredData?.map((ele) => (
              <div key={ele.name} className="flex justify-between ml-4 my-2">
                <p>{addOns[ele.name]}</p>
                <p>{formatAmountInRupees(ele.value)}</p>
              </div>
            ))}
          </div>
        </div>

        <h3 className="mb-1 text-lg font-medium">Taxes</h3>
        {/* GST@18 */}
        <div className="flex justify-between font-medium mb-2 mt-2">
          <p className="text-[#00000094]">GST@18</p>
          <p>{formatAmountInRupees(data.totalTax)}</p>
        </div>

        <hr className="border-slate-300" />

        <div className="flex justify-between text-xl font-medium mt-2">
          {/* Premium */}
          <p>Premium</p>
          <p>{formatAmountInRupees(data.totalAmount)}</p>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
