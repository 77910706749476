import React from "react";

import ModalWithBikeNumber from "./ModalWithBikeNumber";
import ModalNewBike from "./ModalNewBike";

const CommonModal = ({ data, closeModal, modalType }) => {
  if (modalType === "modalwithbikenumber") {
    return <ModalWithBikeNumber data={data} closeModal={closeModal} />;
  } else {
    return <ModalNewBike data={data} closeModal={closeModal} />;
  }
};

export default CommonModal;
