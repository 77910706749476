import React from "react";
import "../App.css";

//components
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

//assets
import blob3 from "../assets/blob3.svg";
import aboutUsBlobMobile from "../assets/aboutUsBlobMobile.svg";
import aboutusTrophy from "../assets/aboutusTrophy.svg";
import aboutusPeople from "../assets/aboutusPeople.svg";
import founder1 from "../assets/founder1.svg";
import founder2 from "../assets/founder2.svg";
import timeline from "../assets/timeline.svg";

const AboutUs = () => {
  const isMobile = window.innerWidth <= 767;
  return (
    <>
      <section className="lg:flex lg:justify-between lg:relative">
        {isMobile && (
          <div className="flex justify-center">
            <img
              className="lg:mt-0 mt-5 w-1/2 h-1/2"
              src={aboutusPeople}
              alt=""
            />
          </div>
        )}
        <article className="lg:w-[40%] lg:p-0 p-5 lg:mt-[80px] lg:ml-[100px]">
          <h2 className="text-[#9D0004] text-center lg:text-start lg:text-3xl text-xl font-medium lg:mb-[25px] mb-2">
            About Us
          </h2>
          <p className="lg:text-xl text-[#00000094]">
            At K Wasan Insurance Broking Private Limited, our dedicated team of
            insurance experts is fully committed to creating personalized
            insurance policies that perfectly align with the specific
            requirements of our valued clients. As proud holders of a valid
            Broker (Life & General Insurance) license issued by IRDAI (License
            No. 821), we cater to clients from all walks of life. Through our
            extensive network, which includes 23 leading General Insurance
            Companies and 3 renowned Life Insurance Companies, we consistently
            enhance our partnerships to provide comprehensive coverage options
            that ensure peace of mind for our cherished clients.
          </p>
        </article>
        {!isMobile && (
          <div className="mr-[100px] absolute top-[110px] right-0">
            <img src={aboutusPeople} alt="" />
          </div>
        )}
      </section>

      <section
        style={{
          backgroundImage: `url(${isMobile ? aboutUsBlobMobile : blob3})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
        }}
        className="flex lg:flex-row flex-col lg:justify-between"
      >
        <div className="lg:mt-[400px] lg:ml-[100px] lg:pb-[300px] lg:pt-0 pt-[100px]">
          <img
            style={{
              width: `${isMobile ? "50%" : "100%"}`,
              margin: `${isMobile ? "auto" : ""}`,
            }}
            alt=""
            src={aboutusTrophy}
          />
        </div>
        <article className="lg:w-[40%] lg:p-0 p-5 lg:mr-[100px] lg:mt-[400px] lg:pb-[300px]">
          <h2 className="text-[#9D0004] lg:text-3xl text-xl lg:text-end text-center font-medium lg:mb-[25px] mb-2">
            Our Success Story
          </h2>
          <p className="lg:text-xl lg:text-end text-[#00000094]">
            Since its establishment in 1961, the Wasan Group has emerged as a
            trusted name in the Indian automobile sales sector. With over five
            decades of experience, we have evolved into a diversified
            conglomerate, partnering with renowned global automobile brands such
            as Daimler, Toyota, Ford, Tata, Bajaj, and Mahindra. At the Wasan
            Group, we take immense pride in upholding our core values, which
            have been instrumental in shaping our success. Our dedicated
            workforce of over 1000 professionals is committed to embodying these
            values in every aspect of our operations. As part of our commitment
            to making a positive impact, we have recently launched the Wasan
            Knowledge Hub. Our vision is to transform lives by providing
            knowledge and skill development opportunities to individuals across
            various industries and walks of life.
          </p>
        </article>
      </section>

      <section>
        <article className="lg:p-0 p-5">
          <header className="text-center lg:mb-[100px]">
            <h2 className="text-[#9D0004] lg:text-3xl text-xl font-medium lg:mb-0 mb-4">
              Comapany Heads
            </h2>
          </header>

          <div className="lg:flex lg:justify-around lg:mb-[100px]">
            <div className="lg:w-1/2">
              {isMobile && (
                <img
                  style={{
                    width: "50%",
                    margin: "auto",
                  }}
                  src={founder1}
                  alt=""
                />
              )}
              <h2 className="text-[#9D0004] lg:text-3xl text-xl lg:text-start text-center font-medium mb-5 lg:mt-0 mt-2">
                Mr. Kasturilal Kundanlal Wasan
              </h2>
              <p className="lg:text-xl text-[#00000094]">
                Meet Mr. Kasturilal Kundanlal Wasan, a visionary leader with
                over four decades of trailblazing success in the passenger and
                commercial vehicles industry. Building upon the rich legacy
                passed down by his late father, Mr. Kundanlal Wasan, he has
                spearheaded the remarkable growth of the Wasan Group. With his
                expert guidance, the group has expanded into various allied and
                non-allied businesses, establishing a formidable presence as a
                renowned market leader
              </p>
            </div>
            {!isMobile && <img src={founder1} alt="" />}
          </div>

          <div className="lg:flex lg:justify-around lg:mt-[100px]">
            {isMobile && (
              <img
                style={{
                  width: "50%",
                  margin: "auto",
                }}
                src={founder2}
                alt=""
              />
            )}
            {!isMobile && <img src={founder2} alt="" />}
            <div className="lg:w-1/2">
              <h2 className="text-[#9D0004] lg:text-3xl text-xl lg:text-start text-center font-medium mb-5 lg:mt-0 mt-2">
                Mr. Kunal Kasturilal Wasan
              </h2>
              <p className="lg:text-xl text-[#00000094]">
                Meet Mr. Kunal Kasturilal Wasan, a visionary leader with
                trailblazing success in the passenger and commercial vehicles
                industry. Mr. Kunal Wasan has spearheaded the remarkable growth
                of the Wasan Group. With his expert guidance, the group has
                expanded into various allied and non-allied businesses,
                establishing a formidable presence as a renowned market leader
              </p>
            </div>
          </div>
        </article>
      </section>

      <img
        className="w-full lg:pb-[100px] pb-11 lg:pt-[200px] pt-16"
        src={timeline}
        alt=""
      />

      <ScrollToTop />

      <Footer />
    </>
  );
};

export default AboutUs;
