import apis from "../helper/allApis";
import { postWithoutToken } from "../helper/Http";

/**
 * @description - Quotation related APIs
 * @auth - required
 * @route /quote
 **/

const getBikeQuotes = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.BIKE_GENERATE_QUOTE}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

const createBikeProposal = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.CREATE_PROPOSAL_BIKE}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: res.data.data,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const createBikeProposalNew = async (reqObj) => {
  try {
    const res = await postWithoutToken(
      `${apis.CREATE_PROPOSAL_BIKE_NEW}`,
      reqObj
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: res.data.data,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const bikePayment = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.PAYMENT_BIKE}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const bikeKycStatus = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.KYC_CAR}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};
export {
  getBikeQuotes,
  createBikeProposal,
  bikePayment,
  bikeKycStatus,
  createBikeProposalNew,
};
