export function formatAmountInRupees(amount) {
  const matches = amount.toString().match(/\d+/);
  if (matches) {
    amount = parseInt(matches[0], 10);
  }

  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  });
  // Remove .00 if present
  const withoutDecimal = formattedAmount.replace(/\.00$/, "");

  return withoutDecimal;
}

export const formatDigitAmount = (amount) => {
  return parseFloat(amount.match(/[\d.]+/)[0]);
};
