import apis from "../helper/allApis";
import {
  getWithoutToken,
  getWithToken,
  postWithoutToken,
} from "../helper/Http";

/**
 * @description - Quotation related APIs
 * @auth - required
 * @route /quote
 **/

const getVehicleDetails = async (vehicleNo) => {
  try {
    const res = await getWithoutToken(
      `${apis.FETCH_VEHICLE_DETAILS}?vehicleNo=${vehicleNo}`
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

const getVehicleCodeFromDb = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.FETCH_VEHICLE_CODE}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

const getNewVehicleCodeFromDb = async (reqObj) => {
  try {
    const res = await postWithoutToken(
      `${apis.FETCH_NEW_VEHICLE_CODE}`,
      reqObj
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

const getKycDetails = async (kycNo, name, dob) => {
  try {
    const res = await getWithoutToken(
      `${apis.FETCH_KYC_DETAILS}?kycNo=${kycNo}&name=${name}&dob=${dob}`
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data?.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

const getPolicyPdf = async ({ policyId, INSURER_NAME }) => {
  try {
    const res = await getWithToken(
      `${apis.POLICY_DOC}?policyId=${policyId}&provider=${INSURER_NAME}`
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

export {
  getVehicleDetails,
  getVehicleCodeFromDb,
  getNewVehicleCodeFromDb,
  getKycDetails,
  getPolicyPdf,
};
