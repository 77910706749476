import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Datepicker from "react-tailwindcss-datepicker";
import Swal from "sweetalert2";
import moment from "moment";

import { BikeInsuranceFormContext } from "../../../pages/BikeInsurance/BikeInsuranceProposal";
import { createBikeProposalNew } from "../../../apis/bikeApis";

function AddressDetails() {
  const navigate = useNavigate();
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    planData,
  } = useContext(BikeInsuranceFormContext);

  const isRegistrationNumberShort = formData.registrationnumber.length <= 4;

  const formik = useFormik({
    initialValues: {
      registeredaddress: formData.registeredaddress,
      pincode: formData.pincode,
      city: formData.city,
      state: formData.state,
      chasisnumber: formData.chasisnumber,
      previousinsurer: formData.previousinsurer,
      previouspolicynumber: formData.previouspolicynumber,
      previousPolicyExpiryDate: formData.previousPolicyExpiryDate,
      policyEndDate: planData.nextPolicyEndDate,
    },
    onSubmit: (values) => {
      const data = { ...formData, ...values };
      setFormData(data);
      // setActiveStepIndex(activeStepIndex + 1);
      if (isRegistrationNumberShort) {
        // Handle the submission for short registration numbers
        handleSubmitForShortRegistrationNumber(data);
      } else {
        // Handle the submission for long registration numbers
        handleSubmitForLongRegistrationNumber(data);
      }
    },
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.empPANNO && userData.empEmail) {
      formik.setFieldValue("employeePanNumber", userData.empPANNO);
      formik.setFieldValue("employeeEmail", userData.empEmail);
    }
  }, []); // Empty dependency array to run this effect only once on mount

  // Define a function to handle submission for short registration numbers
  const handleSubmitForLongRegistrationNumber = (data) => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const handleSubmitForShortRegistrationNumber = async (values) => {
    Swal.showLoading();
    const data = { ...formData, ...values };
    const res = await createBikeProposalNew({
      ...data,
      policyEndDate: planData.nextPolicyEndDate,
      district: formData.state.toUpperCase(),
      INSURER_NAME: planData.INSURER_NAME,
      vehicleNo: formData.registrationnumber,
      manufactureDate: moment().format("YYYY-MM-DD"),
      planData: planData,
      enquiryId: planData.quoteId,
      IDV_of_Vehicle: planData.bikeValue ?? null,
      policyHolderType: "INDIVIDUAL",
      VoluntaryExcess: null,
      addOnPackagesAndCovers: planData.AddOnFilteredData ?? [],
      employeePanNumber: values.employeePanNumber,
      employeeEmail: values.employeeEmail,
    });
    Swal.hideLoading();
    if (res.data?.policyExist) {
      console.log(res);
      Swal.fire({
        icon: "error",
        title: "Policy Exists !",
        text: res?.message,
        confirmButtonText: "Check Purchase Summary",
        confirmButtonColor: "#F8363B",
      }).then(() => {
        navigate("/bikeinsurance/purchasesummary", {
          state: {
            INSURER_NAME: "GODIGIT",
            policyId: res.data?.PolicyId,
          },
        });
      });
    } else if (res.ok) {
      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Proposal created successfully",
        confirmButtonText: "OK",
        confirmButtonColor: "#9D0004",
      });
      navigate("../bikeproposalsummary", {
        state: {
          INSURER_NAME: "GODIGIT",
          policyId: res.data.policyDetails._id,
          proposalInfo: res.data,
        },
      });
    } else {
      console.log(res);
      Swal.fire({
        icon: "error",
        title: "Proposal creation failed",
        text: res?.message,
      });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-2">
      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">
            Registered Address
          </label>
          <input
            type="text"
            name="registeredaddress"
            required
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 focus:outline-none bg-slate-100"
            placeholder="Enter Address"
            value={formik.values.registeredaddress}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.registeredaddress &&
            formik.errors.registeredaddress ? (
              <div>{formik.errors.registeredaddress}</div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Pincode</label>
          <input
            type="text"
            name="pincode"
            required
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 focus:outline-none bg-slate-100"
            placeholder="Enter Pincode"
            value={formik.values.pincode}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.pincode && formik.errors.pincode ? (
              <div>{formik.errors.pincode}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">State</label>
          <input
            type="text"
            name="state"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter State"
            value={formik.values.state}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.state && formik.errors.state ? (
              <div>{formik.errors.state}</div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">City</label>
          <input
            type="text"
            name="city"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter City"
            value={formik.values.city}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.city && formik.errors.city ? (
              <div>{formik.errors.city}</div>
            ) : null}
          </div>
        </div>
      </div>

      {isRegistrationNumberShort && (
        <div className="lg:flex lg:space-x-8">
          <div className="flex flex-col items-start mb-2">
            <label className="font-medium text-gray-900">Chassis Number</label>
            <input
              required={true}
              type="text"
              name="chasisnumber"
              className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
              placeholder="Enter Chassis Number"
              value={formik.values.chasisnumber}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      )}

      {isRegistrationNumberShort && (
        <div className="lg:flex lg:space-x-8">
          <div className="flex flex-col items-start mb-2">
            <label className="font-medium text-gray-900">Policy End Date</label>
            <Datepicker
              popoverDirection="up"
              inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
              primaryColor={"rose"}
              useRange={false}
              asSingle={true}
              value={{
                startDate: formik.values.policyEndDate,
                endDate: formik.values.policyEndDate,
              }}
              minDate={new Date()}
              onChange={(selectDate) => {
                console.log(selectDate);
                formik.setFieldValue("policyEndDate", selectDate.startDate);
              }}
            />
          </div>
        </div>
      )}

      {!isRegistrationNumberShort && (
        <div className="lg:flex lg:space-x-8">
          <div className="flex flex-col items-start mb-2">
            <label className="font-medium text-gray-900">
              Previous Insurer
            </label>
            <input
              required
              type="text"
              name="previousinsurer"
              className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
              placeholder="Enter Details"
              value={formik.values.previousinsurer}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col items-start mb-2">
            <label className="font-medium text-gray-900">
              Previous Policy Number
            </label>
            <input
              required
              type="text"
              name="previouspolicynumber"
              className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
              placeholder="Enter Number"
              value={formik.values.previouspolicynumber}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      )}

      {!isRegistrationNumberShort && (
        <div className="lg:flex lg:space-x-8">
          <div className="flex flex-col items-start mb-2">
            <label className="font-medium text-gray-900">
              Previous Policy End date
            </label>
            <Datepicker
              popoverDirection="up"
              inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
              primaryColor={"rose"}
              useRange={false}
              asSingle={true}
              value={{
                startDate: formik.values.previousPolicyExpiryDate,
                endDate: formik.values.previousPolicyExpiryDate,
              }}
              onChange={(selectDate) => {
                formik.setFieldValue(
                  "previousPolicyExpiryDate",
                  selectDate.startDate
                );
              }}
            />
          </div>
        </div>
      )}

      <div className="flex lg:flex-row lg:justify-between flex-col items-center lg:mb-4">
        <div className="lg:mb-0 mb-6 lg:mt-0 mt-4">
          <button
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
            onClick={() => setActiveStepIndex(activeStepIndex - 1)}
          >
            Previous
          </button>
        </div>
        <div>
          <button
            className={`bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40 ${
              isRegistrationNumberShort ? "cursor-pointer" : ""
            }`}
            type="submit"
          >
            {isRegistrationNumberShort ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddressDetails;
