import React, { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import "./NavDropdownStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Dropdown = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Insurance Products");

  const handleOptionClick = useCallback((optionText) => {
    setSelectedOption(optionText);
    setIsHovered(false);
  }, []);

  return (
    <div
      className={`select-menu ${isHovered ? "active" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="select-btn">
        <span className="sBtn-text">{selectedOption}</span>
        {/* <i className="bx bx-chevron-down"></i> */}
        <FontAwesomeIcon
          icon={isHovered ? faChevronUp : faChevronDown}
          className="p-1 transition-transform h-4 w-4 text-black"
        />
      </div>

      <ul className="options">
        <NavLink
          to="/carinsurance"
          className="option"
          onClick={() => handleOptionClick("Car Insurance")}
        >
          {/* <img src={CarIcon} className="w-7 h-8" alt="" /> */}
          <span className="option-text">Car Insurance</span>
        </NavLink>
        <NavLink
          to="/bikeinsurance"
          className="option"
          onClick={() => handleOptionClick("Bike Inusrance")}
        >
          {/* <img src={BikeIcon} className="w-7 h-8" alt="" /> */}
          <span className="option-text">Bike Inusrance</span>
        </NavLink>
        {/* <NavLink
          to="/healthinsurance"
          className="option"
          onClick={() => handleOptionClick("Health Insurance")}
        >
          <span className="option-text">Health Insurance</span>
        </NavLink> */}
      </ul>
    </div>
  );
};

export default Dropdown;
