import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

//apis
import { bikePayment } from "../../apis/bikeApis";
import { hdfcCheckSum } from "../../apis/carApis";

const ShriramPaymentComponent = (data) => {
  const { paymentId, paymentAmount } = data.data.data;

  return (
    <div className="w-full h-full flex justify-center p-5">
      <form
        action="https://novaapiuat.shriramgi.com/uatnovapymt/mydefaultcc.aspx"
        method="post"
      >
        <input name="createdBy" value="LC331" />
        <input type="hidden" name="createdBy" value="LC331" />
        <input type="hidden" name="paymentFrom" value="CCAVENUE" />
        <input type="hidden" name="prodCode" value="MOT-PRD-001" />
        <input type="hidden" name="QuoteId" value={paymentId} />
        <input type="hidden" name="amount" value={paymentAmount} />
        <input
          type="hidden"
          name="sourceUrl"
          value="https://uat.kwasaninsurance.com/"
        />
        <input type="hidden" name="DbFrom" value="NOVA" />
        <input type="hidden" name="application" value="WasanIns" />
        <input type="hidden" name="prodName" value="MOTOR" />
        {/* <input type="submit" value="Submit" /> */}
        <button
          type="submit"
          className="bg-[#F8363B] p-3 rounded-full text-white"
        >
          Proceed To Payment
        </button>
      </form>
    </div>
  );
};

const HdfcPaymentComponent = (data) => {
  const [hdfcInfo, setHdfcInfo] = useState({});
  const { paymentId, paymentAmount } = data.data.data;
  console.log(paymentId, paymentAmount);

  useEffect(() => {
    try {
      const execute = async () => {
        const res = await hdfcCheckSum(paymentId, paymentAmount);
        if (res.ok) setHdfcInfo(res.data);
        else {
          Swal.fire({
            icon: "error",
            title: "HDFC generate checksum failed",
          });
        }
      };
      execute();
    } catch (err) {
      console.log(err);
    }
  }, [paymentId, paymentAmount]);

  return (
    <div className="w-full h-full flex justify-center p-5">
      <form
        action="http://202.191.196.210/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx"
        // name="PAYMENTFORM"
        method="post"
        // _lpchecked="1"
      >
        <input type="hidden" name="appid" value={hdfcInfo.AppID} />
        <input type="hidden" name="subid" value={hdfcInfo.SubscriptionID} />
        <input type="hidden" name="trnsno" value={hdfcInfo.TransactionNo} />
        <input type="hidden" name="amt" value={hdfcInfo.TotalAmount} />
        <input type="hidden" name="surl" value={hdfcInfo.SuccessUrl} />
        <input type="hidden" name="furl" value={hdfcInfo.FailureUrl} />
        <input type="hidden" name="src" value={hdfcInfo.Source} />
        <input type="hidden" name="chksum" value={hdfcInfo.checkSum} />
        <button
          type="submit"
          className="bg-[#F8363B] p-3 rounded-full text-white"
        >
          Proceed To Payment
        </button>
      </form>
    </div>
  );
};

const GodigitPaymentComponent = (data) => {
  // const { policyId, INSURER_NAME } = data.data.data;
  const { policyId, INSURER_NAME, proposalInfo } = data.data.data;
  const { policyDetails } = proposalInfo;
  const refPolicyNumber = policyDetails.policyNumber;
  // console.log("GODIGIT payment ", data.data.data);
  const handlePayment = async () => {
    // Check the condition for showing "Next" button
    if (
      policyDetails.insuranceDetails.test.motorBreakIn.isBreakin === true &&
      policyDetails.insuranceDetails.test.preInspection
        .preInspectionReasons[0] === "BREAKIN_PI"
    ) {
      Swal.fire({
        title:
          "Dear customer, you will receive a message shortly to do a vehicle inspection",
        text: `Follow the steps to purchase your policy. This is your policy number for reference ${refPolicyNumber}`,
        icon: "info",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title:
              "You will receive the status of your policy and the payment link after you complete the steps mentioned in the SMS/email.",
            icon: "info",
          });
        }
      });
    } else {
      const res = await bikePayment({
        policyId: policyId,
        INSURER_NAME,
      });
      if (res.ok) {
        console.log(res.data);
        window.location.href = res.data;
      } else if (!res.ok && res.data === null) {
        Swal.fire({
          icon: "error",
          title: res.message,
          text: "Please contact K Wasan Insurance team at 022 6683 2222 for further procedure",
        });
        console.log(res);
      }
    }
  };

  return (
    <div className="w-full h-full flex justify-center p-5">
      {policyDetails.insuranceDetails.test.motorBreakIn.isBreakin === true &&
      policyDetails.insuranceDetails.test.preInspection
        .preInspectionReasons[0] === "BREAKIN_PI" ? (
        <button
          className="bg-[#9D0004] p-3 rounded-xl text-white"
          onClick={handlePayment}
        >
          Next
        </button>
      ) : (
        <button
          className="bg-[#9D0004] p-3 rounded-xl text-white"
          onClick={handlePayment}
        >
          Proceed To Payment
        </button>
      )}
    </div>
  );
};

const PaymentComponent = (data) => {
  const { INSURER_NAME } = data.data;
  const components = {
    SHRIRAM: ShriramPaymentComponent,
    GODIGIT: GodigitPaymentComponent,
    HDFC: HdfcPaymentComponent,
  };
  const Component = components[INSURER_NAME];
  return <Component data={data} />;
};

const ProposalSummaryCard = ({ title, fields }) => {
  return (
    <div className="bg-white shadow border-2 rounded-2xl lg:p-5 lg:w-3/4 w-4/5 lg:m-0 m-5 mx-auto lg:mx-0">
      <div>
        <p className="text-xl lg:text-2xl text-center lg:mb-5 text-[#9D0004]">
          {title}
        </p>
        <div className="flex flex-col lg:p-0 p-4">
          {fields.map((field) => (
            <div
              key={field.label}
              className="lg:flex lg:justify-between items-center"
            >
              {field.value !== "N/A" ? (
                <>
                  <p className="font-medium lg:text-lg lg:mt-0 mt-2">
                    {field.label}
                  </p>
                  <p className="lg:text-lg lg:mt-0 mt-2">{field.value}</p>
                </>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BikeProposalSummary = () => {
  const location = useLocation();
  const { policyId, INSURER_NAME, proposalInfo } = location.state;
  const data = {
    policyId: policyId ?? "11391902000001",
    INSURER_NAME: INSURER_NAME ?? "HDFC",
  };
  const { userDetails, vehicleDetails, policyDetails } = proposalInfo;
  const basicDetailsFields = [
    // { label: "Registration-Number", value: "Registration Number" },
    // { label: "Customer Name", value: "Customer Name" },
    // { label: "Mobile Number", value: "Mobile Number" },
    // { label: "Email", value: "Email" },
    { label: "Registration-Number", value: vehicleDetails.vehicleNo },
    { label: "Customer Name", value: userDetails.name },
    { label: "Mobile Number", value: userDetails.phoneNumber },
    { label: "Email", value: userDetails.email },
  ];

  const nomineeDetailsFields = [
    // { label: "Nominee Name", value: "Nominee Name" },
    // { label: "Nominee DOB", value: "Nominee DOB" },
    // { label: "Nominee Relation", value: "Nominee Relation" },
    { label: "Nominee Name", value: policyDetails.nomineeName },
    { label: "Nominee DOB", value: policyDetails.nomineeDob },
    { label: "Nominee Relation", value: policyDetails.nomineeRelation },
  ];

  const addressFields = [
    // { label: "Registered-Address", value: "Registered Address" },
    // { label: "State", value: "State" },
    // { label: "City", value: "City" },
    // { label: "Pincode", value: "Pincode" },
    { label: "Registered-Address", value: userDetails.address },
    { label: "State", value: userDetails.state },
    { label: "City", value: userDetails.city },
    { label: "Pincode", value: userDetails.pincode },
  ];

  const vehicleDetailsFields = [
    // { label: "Registration Date", value: "Registration Date" },
    // { label: "Engine Number", value: "Engine Number" },
    // { label: "Chasis Number", value: "Chasis Number" },
    // { label: "Previous Insurer", value: "Previous Insurer" },
    // { label: "Previous Policy Number", value: "Previous Policy Number" },
    // { label: "Previous Policy Expiration Date", value: "Expiration Date" },
    // { label: "Registration Date", value: vehicleDetails.registrationDate },
    // { label: "Engine Number", value: vehicleDetails.engineNo },
    // { label: "Chasis Number", value: vehicleDetails.chasisNo },
    // { label: "Previous Insurer", value: vehicleDetails.prevInsurarname },
    // { label: "Previous Policy Number", value: vehicleDetails.prevPolicyNo },
    // {
    //   label: "Previous Policy Expiration Date",
    //   value: vehicleDetails.prevPolicyExpireDate,
    // },
    { label: "Registration Date", value: vehicleDetails.registrationDate },
    {
      label: "Engine Number",
      value: !vehicleDetails.engineNo ? "N/A" : vehicleDetails.engineNo,
    },
    {
      label: "Chasis Number",
      value: !vehicleDetails.chasisNo ? "N/A" : vehicleDetails.chasisNo,
    },
    {
      label: "Previous Insurer",
      value: !vehicleDetails.prevInsurarname
        ? "N/A"
        : vehicleDetails.prevInsurarname,
    },
    {
      label: "Previous Policy Number",
      value: !vehicleDetails.prevPolicyNo ? "N/A" : vehicleDetails.prevPolicyNo,
    },
    {
      label: "Previous Policy Expiration Date",
      value: !vehicleDetails.prevPolicyExpireDate
        ? "N/A"
        : vehicleDetails.prevPolicyExpireDate,
    },
  ];

  return (
    <>
      <section>
        <article className="bg-gradient-to-b from-[#faf5ea] to-transparent">
          <h2 className="lg:p-8 p-4 lg:text-4xl text-2xl font-medium text-center text-[#9D0004]">
            Proposal Summary
          </h2>

          {/* <div className="p-5 gap-6 flex justify-evenly">
            <div className="bg-white shadow border-2 rounded-2xl p-5 w-3/4">
              <div>
                <p className="text-2xl text-center mb-5 text-[#9D0004]">
                  Basic Details
                </p>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p className="font-medium">Registration Number</p>
                    <p>Registration Number</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Customer Name</p>
                    <p>Customer Name</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Mobile Number</p>
                    <p>Mobile Number</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Email</p>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow border-2 rounded-2xl p-5 w-3/4">
              <div>
                <p className="text-2xl text-center mb-5 text-[#9D0004]">
                  Nominee Details
                </p>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p className="font-medium">Nominee Name</p>
                    <p>Nominee Name</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Nominee DOB</p>
                    <p>Nominee DOB</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Nominee Relation</p>
                    <p>Nominee Relation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 gap-6 flex justify-evenly">
            <div className="bg-white shadow border-2 rounded-2xl p-5 w-3/4">
              <div>
                <p className="text-2xl text-center mb-5 text-[#9D0004]">
                  Address
                </p>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p className="font-medium">Registered Address</p>
                    <p>Registered Address</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">State</p>
                    <p>State</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">City</p>
                    <p>City</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Pincode</p>
                    <p>Pincode</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow border-2 rounded-2xl p-5 w-3/4">
              <div>
                <p className="text-2xl text-center mb-5 text-[#9D0004]">
                  Vehicle Details
                </p>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p className="font-medium">Registration Date</p>
                    <p>Registration Date</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Engine Number</p>
                    <p>Engine Number</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Chasis Number</p>
                    <p>Chasis Number</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Previous Insurer</p>
                    <p>Previous Insurer</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Previous Poilcy Number</p>
                    <p>Previous Poilcy Number</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-semibold">
                      Previous Poilcy Expiration Date
                    </p>
                    <p>Expiration Date</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="lg:p-5 lg:gap-6 lg:flex lg:justify-evenly">
            <ProposalSummaryCard
              title="Basic Details"
              fields={basicDetailsFields}
            />
            <ProposalSummaryCard
              title="Nominee Details"
              fields={nomineeDetailsFields}
            />
          </div>

          <div className="lg:p-5 lg:gap-6 lg:flex lg:justify-evenly">
            <ProposalSummaryCard title="Address" fields={addressFields} />
            <ProposalSummaryCard
              title="Vehicle Details"
              fields={vehicleDetailsFields}
            />
          </div>
          <PaymentComponent data={data} />
        </article>
      </section>
    </>
  );
};

export default BikeProposalSummary;
