import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const listOptions = [
  { id: 1, value: "roadSideAssistance", description: "Roadside Assistance" },
  { id: 2, value: "engineProtection", description: "Engine Protection" },
  { id: 3, value: "tyreProtection", description: "Tyre Protection" },
  { id: 4, value: "rimProtection", description: "Rim Protection" },
  { id: 5, value: "returnToInvoice", description: "Return To Invoice" },
  { id: 6, value: "consumables", description: "Consumables" },
  { id: 7, value: "partsDepreciation", description: "Parts Depreciation" },
  { id: 8, value: "keyAndLockProtect", description: "Key And Lock Protect" },
  { id: 9, value: "personalBelonging", description: "Personal Belonging" },
];

const AddOnDrop = (props) => {
  const { toolbarOptions, onToolbarOptionChange } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionToggle = (optionId) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
    } else {
      setSelectedOptions([...selectedOptions, optionId]);
    }
  };

  const handleAddOnApply = () => {
    console.log(selectedOptions);
    const toolbarObj = {
      ...toolbarOptions,
      addOnPackages: selectedOptions,
    };
    onToolbarOptionChange(toolbarObj);
  };

  return (
    <div className="lg:relative inline-block">
      <button
        type="button"
        className="w-full bg-white border border-gray-300 rounded-md shadow-sm p-2 text-sm font-medium text-gray-700 flex items-center justify-between"
        onClick={props.changeSelectedMenu}
      >
        <span className="mr-2">
          {selectedOptions.length > 0
            ? `${selectedOptions.length} selected`
            : "Select Addons"}
        </span>
        <FontAwesomeIcon
          icon={props.selectedMenu === "addon" ? faChevronUp : faChevronDown}
          className="h-4 w-4 text-gray-600"
        />
      </button>
      {props.selectedMenu === "addon" && (
        <div className="lg:absolute w-max mt-2 py-2 bg-white border border-gray-300 rounded-md shadow-lg">
          {listOptions.map((option) => (
            <label
              key={option.id}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              <input
                type="checkbox"
                className="form-checkbox mr-2"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionToggle(option.value)}
              />
              <span className="text-gray-800">{option.description}</span>
            </label>
          ))}
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAddOnApply();
            }}
            className="bg-[#9D0004] mx-7 p-2 rounded-lg text-white"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default AddOnDrop;
