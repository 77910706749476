import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Carousel, initTE } from "tw-elements";
import "../App.css";

//components
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
//assets
import whyWasan1 from "../assets/whywasan1.svg";
import whyWasan2 from "../assets/whywasan2.svg";
import whyWasan3 from "../assets/whywasan3.svg";
import whyWasan4 from "../assets/whywasan4.svg";
import whyWasan5 from "../assets/whywasan5.svg";

// import partner1 from "../assets/insuranceProviders/partner1.svg";
// import partner2 from "../assets/insuranceProviders/partner2.svg";
// import partner3 from "../assets/insuranceProviders/partner3.svg";
// import partner4 from "../assets/insuranceProviders/partner4.svg";
// import partner5 from "../assets/insuranceProviders/partner5.svg";
// import partner6 from "../assets/insuranceProviders/partner6.svg";

import careInsurance from "../assets/insuranceProviders/care-insurance.webp";
import digitInsurance from "../assets/insuranceProviders/digit-insuarance.webp";
import kotakInsurance from "../assets/insuranceProviders/kotak-insurance.webp";
import fgInsurance from "../assets/insuranceProviders/future-generali.webp";
import hdfcErgoInsurance from "../assets/insuranceProviders/hdfc-ergo.webp";
import icicInsurance from "../assets/insuranceProviders/icici-lombard.webp";
import relianceInsurance from "../assets/insuranceProviders/reliance-health.webp";
import sbiInsurance from "../assets/insuranceProviders/sbi-general.webp";
import shriramInsurance from "../assets/insuranceProviders/shriram-general.webp";
import tataInsurance from "../assets/insuranceProviders/tata-aig.webp";
import universalInsurance from "../assets/insuranceProviders/universal-sompo.webp";

import bikeService from "../assets/bikeService.svg";
import carService from "../assets/carService.svg";
// import healthService from "../assets/healthService.svg";
// import petService from "../assets/petService.svg";
// import termService from "../assets/termService.svg";
// import homeService from "../assets/homeService.svg";
// import travelService from "../assets/travelService.svg";

import timeline from "../assets/timeline.svg";
// import reviewer from "../assets/reviewer.svg";
import homeBlob from "../assets/homeBlob.svg";
import homeBlobMobile from "../assets/homeBlobMobile.svg";
import homeImage1 from "../assets/homeImage1.svg";
import bot from "../assets/bot.svg";

import pattern1 from "../assets/pattern1.svg";
import pattern2 from "../assets/pattern2.svg";
import pattern3 from "../assets/pattern3.svg";
import pattern4 from "../assets/pattern4.svg";
import pattern5 from "../assets/pattern5.svg";

const Slider = () => {
  useEffect(() => {
    initTE({ Carousel });
  }, []);

  const scrollToOurServices = () => {
    const ourServicesSection = document.getElementById("our-services");

    if (ourServicesSection) {
      ourServicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex justify-center items-center lg:mt-10 mx-auto mt-5 lg:h-[500px] h-[380px]">
      <div
        id="carouselExampleCaptions"
        className="items-center"
        data-te-carousel-init
        data-te-ride="carousel"
      >
        {/* <!--Carousel indicators--> */}
        {/* <div
          className="absolute lg:bottom-0 bottom-56 left-0 right-0 lg:z-[2] lg:mx-[15%] lg:mb-4 flex list-none justify-center"
          data-te-carousel-indicators
        >
          <button
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide-to="0"
            data-te-carousel-active
            className="mx-[3px] box-content lg:h-[10px] h-[8px] lg:w-[28px] w-[25px] flex-initial cursor-pointer border-0 border-solid border-transparent bg-black bg-clip-padding p-0 opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none rounded-full"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide-to="1"
            className="mx-[3px] box-content lg:h-[10px] h-[8px] lg:w-[28px] w-[25px] flex-initial cursor-pointer border-0 border-solid border-transparent bg-black bg-clip-padding p-0 opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none rounded-full"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide-to="2"
            className="mx-[3px] box-content lg:h-[10px] h-[8px] lg:w-[28px] w-[25px] flex-initial cursor-pointer border-0 border-solid border-transparent bg-black bg-clip-padding p-0 opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none rounded-full"
            aria-label="Slide 3"
          ></button>
        </div> */}

        {/* <!--Carousel items--> */}
        <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          {/* <!--First item--> */}
          <div
            className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-active
            data-te-carousel-item
            style={{ backfaceVisibility: "hidden" }}
          >
            {/* absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block */}
            <div className="flex flex-col justify-center w-full text-center lg:p-0 first-letter:p-4">
              <p className="lg:text-3xl text-xl lg:mb-8 mb-4 text-[#9D0004] font-medium">
                Wasan Insurance
              </p>
              <p className="lg:text-6xl text-3xl lg:w-3/5 lg:mb-5 mb-2 self-center">
                Har Mushkil Mai Aapke Saath
              </p>
              <p className="lg:text-xl text-lg lg:w-[55%] self-center text-[#00000094]">
                Insurance That Feels Like Family - Protecting Your Today for a
                Secured Tomorrow
              </p>
            </div>
            <div className="flex justify-center lg:mt-14 mt-5">
              <button
                className="bg-[#9D0004] rounded-lg hover:shadow-md text-white text-lg p-3 w-2/5"
                type="button"
                onClick={scrollToOurServices}
              >
                Get a Quote
              </button>
            </div>
          </div>
          {/* <!--Second item--> */}
          {/* <div
            className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
            style={{ backfaceVisibility: "hidden" }}
          >
            <div className="flex flex-col justify-center w-full text-center">
              <p className="text-xl">Wasan Insurance</p>
              <p className="text-6xl w-4/5 self-center">
                Har Mushkil Mai Aapke Saath
              </p>
              <p>
                Insurance That Feels Like Family - Protecting Your Today for a
                Secured Tomorrow
              </p>
            </div>
          </div> */}
          {/* <!--Third item--> */}
          {/* <div
            className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-item
            style={{ backfaceVisibility: "hidden" }}
          >
            <div className="flex flex-col justify-center w-full text-center">
              <p className="text-xl">Wasan Insurance</p>
              <p className="text-6xl w-4/5 self-center">
                Har Mushkil Mai Aapke Saath
              </p>
              <p>
                Insurance That Feels Like Family - Protecting Your Today for a
                Secured Tomorrow
              </p>
            </div>
          </div> */}
        </div>

        {/* <!--Carousel controls - prev item--> */}
        {/* <button
          className="text-center absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-[#9D0004] opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-[#9D0004] hover:no-underline hover:opacity-100 hover:outline-none focus:text-[#9D0004] focus:no-underline focus:opacity-100 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleCaptions"
          data-te-slide="prev"
        >
          <span className="inline-block h-8 w-8">
            <svg
              viewBox="-0.5 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                  stroke="#9D0004"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
                <path
                  d="M13.4102 16.4199L10.3502 13.55C10.1944 13.4059 10.0702 13.2311 9.98526 13.0366C9.9003 12.8422 9.85645 12.6321 9.85645 12.4199C9.85645 12.2077 9.9003 11.9979 9.98526 11.8035C10.0702 11.609 10.1944 11.4342 10.3502 11.29L13.4102 8.41992"
                  stroke="#9D0004"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </span>
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Previous
          </span>
        </button> */}
        {/* <!--Carousel controls - next item--> */}
        {/* <button
          className="text-center absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-[#9D0004] opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-[#9D0004] hover:no-underline hover:opacity-100 hover:outline-none focus:text-[#9D0004] focus:no-underline focus:opacity-100 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleCaptions"
          data-te-slide="next"
        >
          <span className="inline-block h-8 w-8">
            <svg
              viewBox="-0.5 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                  stroke="#9D0004"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
                <path
                  d="M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199"
                  stroke="#9D0004"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </span>
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Next
          </span>
        </button> */}
      </div>
    </div>
  );
};

const FeatureCard = ({ title, description, image }) => {
  return (
    <article className="bg-[#FCDADB] items-center lg:w-1/3 w-full shadow-md rounded-lg lg:p-5 p-2">
      <div className="flex items-center lg:mb-4 mb-2">
        <p className="text-[#9D0004] font-medium text-lg">{title}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-sm lg:text-base lg:w-3/5 w-3/4">{description}</p>
        <img className="flex lg:w-2/5 w-1/4" src={image} alt={title} />
      </div>
    </article>
  );
};

const OurServicesCard = ({ title, description, image }) => {
  const isMobile = window.innerWidth <= 767;

  return (
    <article className="relative flex flex-col bg-white lg:w-64 h-20 lg:h-64 shadow-md rounded-lg lg:m-5 m-2 mt-6">
      <div className="p-4">
        <h5 className="lg:mb-2 text-lg sm:text-lg md:text-xl lg:text-xl text-[#9D0004]">
          {title}
        </h5>
        {!isMobile && (
          <p className="mb-4 text-sm sm:text-base md:text-base lg:text-md text-black w-[80%] sm:w-4/5 md:w-4/5 lg:w-4/5">
            {description}
          </p>
        )}
      </div>
      <img
        className="absolute bottom-0 right-0 w-[50%] h-[90%] sm:w-1/3 sm:h-3/5 md:w-1/3 md:h-3/5 lg:w-1/2 lg:h-2/5"
        src={image}
        alt="service1"
      />
    </article>
  );
};

const ScrollingCards = ({ cardData }) => {
  // return (
  //   <div className="flex flex-nowrap overflow-x-auto scrolling-wrapper gap-6 ml-10 mr-10 p-5">
  //     {cardData.map((card, index) => (
  //       <div className="card p-4" key={index}>
  //         <img src={card.image} alt={card.alt} />
  //       </div>
  //     ))}
  //   </div>
  // );

  useEffect(() => {
    const slideTrack = document.querySelector(".logo-slide-track");

    const cloneSlides = () => {
      const slides = document.querySelectorAll(".slide");
      slides.forEach((slide) => {
        const clone = slide.cloneNode(true);
        slideTrack.appendChild(clone);
      });
    };

    const startAnimation = () => {
      slideTrack.style.animation = "scroll 20s linear infinite";
    };

    cloneSlides();
    startAnimation();
  }, []);

  return (
    <div className="lg:pb-20 logo-slider">
      <div className="logo-slide-track">
        {cardData.map((card, index) => (
          <div className="slide">
            <img alt={card.alt} src={card.image} />
          </div>
        ))}
      </div>
    </div>
  );
};

// const Testimonial = ({ title, name, review }) => {
//   return (
//     <>
//       <article className="bg-[#FCDADB] p-5 shadow-md rounded-lg lg:w-[350px] w-3/4 lg:h-[250px] h-full flex-shrink-0">
//         <div className="flex flex-row mb-4">
//           <img src={reviewer} alt="reviewer" />
//           <div className="ml-4">
//             <p className="text-[#9D0004] font-medium text-sm sm:text-md md:text-md lg:text-md xl:text-lg">
//               {title}
//             </p>
//             <p>{name}</p>
//           </div>
//         </div>
//         <p>{review}</p>
//       </article>
//     </>
//   );
// };

const Home = () => {
  const isMobile = window.innerWidth <= 767;
  const cardData = [
    { image: careInsurance, alt: "partner1" },
    { image: digitInsurance, alt: "partner2" },
    { image: kotakInsurance, alt: "partner3" },
    { image: fgInsurance, alt: "partner4" },
    { image: hdfcErgoInsurance, alt: "partner5" },
    { image: icicInsurance, alt: "partner6" },
    { image: relianceInsurance, alt: "partner7" },
    { image: sbiInsurance, alt: "partner8" },
    { image: shriramInsurance, alt: "partner9" },
    { image: tataInsurance, alt: "partner10" },
    { image: universalInsurance, alt: "partner11" },
  ];

  return (
    <div>
      <Slider />
      {/* Our Services */}
      <section
        id="our-services"
        className="lg:pb-[200px] pb-[100px] lg:pt-[250px] pt-[220px]"
        style={{
          // backgroundImage: `url(${homeBlob})`,
          backgroundImage: `url(${isMobile ? homeBlobMobile : homeBlob})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // width: "100%",
          // height: "100%",
          // paddingBottom: 200,
          // paddingTop: 250,
        }}
      >
        <div>
          <article className="flex flex-col justify-center items-center">
            <p className="text-center text-[#9D0004] font-medium text-xl md:text-xl lg:text-2xl xl:text-2xl">
              Our Services
            </p>
            <p className="lg:mt-4 mt-2 lg:w-[30%] text-center text-[#9D0004] text-base md:text-lg lg:text-lg xl:text-xl">
              Discover a range of insurance options designed to safeguard what
              matters most to you.
            </p>
          </article>

          <div className="lg:mb-72">
            <article className="lg:flex lg:flex-row justify-evenly flex-wrap m-4">
              <NavLink to="/carInsurance">
                <OurServicesCard
                  title="Car Insurance"
                  description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                  image={carService}
                />
              </NavLink>
              <NavLink to="/bikeInsurance">
                <OurServicesCard
                  title="Bike Insurance"
                  description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                  image={bikeService}
                />
              </NavLink>
              {/* <NavLink to="/healthInsurance">
                <OurServicesCard
                  title="Health Insurance"
                  description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                  image={healthService}
                />
              </NavLink> */}
              {/* <h1 className="font-medium lg:mt-72 mt-20 text-[#9D0004] text-center lg:text-3xl text-xl">
                More Products coming soon, Stay connected....
              </h1> */}
              {/* <OurServicesCard
                title="Pet Insurance"
                description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                image={petService}
              /> */}
            </article>
            <h1 className="font-medium  mt-20 text-[#9D0004] text-center lg:text-3xl text-xl">
              More Products coming soon, Stay connected....
            </h1>
            {/* <article className="lg:flex lg:flex-row justify-evenly flex-wrap m-4">
              <OurServicesCard
                title="Travel Insurance"
                description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                image={travelService}
              />
              <OurServicesCard
                title="Term Insurance"
                description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                image={termService}
              />
              <OurServicesCard
                title="Home Insurance"
                description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                image={homeService}
              />
              <OurServicesCard
                title="Travel Insurance"
                description="Shield yourself from unexpected expenses and safeguard your vehicle with car insurance"
                image={travelService}
              />
            </article> */}
          </div>
        </div>
      </section>

      {/* Why Wasan */}
      <section className="lg:pb-[200px] pb-[50px]">
        <article className="flex flex-col justify-center items-center">
          <p className="text-center font-medium text-[#9D0004] text-xl md:text-xl lg:text-2xl xl:text-2xl">
            Why K Wasan Insurance?
          </p>
          <p className="lg:mt-4 mt-2 w-[90%] lg:w-[40%] text-center text-[#9D0004] text-base md:text-lg lg:text-lg xl:text-xl">
            Because we care about you and those you care about. From
            safeguarding your assets to prioritizing your secured future with
            comprehensive coverage, we do it all!
          </p>
        </article>

        <div>
          <article className="lg:pl-10 lg:pr-10 lg:pt-10 pl-8 pr-8 pt-8 lg:gap-10 gap-8 w-full flex lg:flex-row flex-col justify-center">
            <FeatureCard
              title="Unbiased Advice"
              description="Catering a team of experienced and knowledgeable insurance professionals to provide you with expert and unbiased advice and guidance for informed decisions."
              image={whyWasan1}
            />
            <FeatureCard
              title="Rewards and Incentives"
              description="Exciting rewards, discounts, or other incentives for our loyal customers or those who refer new businesses."
              image={whyWasan2}
            />
            <FeatureCard
              title="Personalized Customer Service"
              description="We prioritize personalized services, quick responses, and effective solutions to exceed your expectations and build long-term relationships."
              image={whyWasan3}
            />
          </article>

          <article className="lg:pl-10 lg:pr-10 lg:pt-10 pl-8 pr-8 pt-8 lg:gap-10 gap-8 w-full flex lg:flex-row flex-col justify-center">
            <FeatureCard
              title="Affordable Premiums"
              description="Experience competitive premiums without compromising on
              quality or our services. Find affordable coverage that meets
              your requirements."
              image={whyWasan4}
            />
            <FeatureCard
              title="Comprehensive Coverage"
              description="A comprehensive range of coverage options catering to your
              diverse insurance needs. From safeguarding assets to securing
              your future, we are a one-stop shop for insurance."
              image={whyWasan5}
            />
          </article>
        </div>
      </section>

      {/* Insurance Partners */}
      <section className="lg:pb-20 ">
        <div className="flex justify-center items-center">
          <p className="text-center font-medium text-[#9D0004] text-xl md:text-xl lg:text-2xl xl:text-2xl">
            Our Insurance Partners
          </p>
        </div>

        <ScrollingCards cardData={cardData} />
        {/* <ScrollingCards /> */}
      </section>

      {!isMobile ? (
        <section className="lg:pb-[180px]">
          <img className="w-full" src={timeline} alt="timeline" />
        </section>
      ) : (
        ""
      )}

      {/* Testimonials */}
      {/* <section className="mb-10">
        <article className="flex flex-col justify-center items-center mb-10">
          <p className="text-center text-[#9D0004] font-medium text-xl md:text-xl lg:text-2xl xl:text-2xl">
            Customer Testimonials
          </p>
          <p className="lg:mt-4 mt-2 text-center lg:w-1/3 w-[90%] text-[#9D0004] text-base md:text-lg lg:text-lg xl:text-xl">
            Don't just take our word for it - hear from our happy customers and
            experience what sets us apart.
          </p>
        </article>
        <article className="flex overflow-x-scroll pt-0 lg:p-10 p-5 gap-8 testimonial-card">
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
          <Testimonial
            title="Really easy getting approval"
            name="John Doe"
            review="Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie. Lorem ipsum dolor sit amet consectetur. Luctus lectus interdum turpis molestie."
          />
        </article>
      </section> */}
      <ScrollToTop />
      <Footer />

      {/* svg blobs */}
      {/* Hero Section */}
      <div>
        <img
          loading="lazy"
          className="absolute lg:w-max lg:h-max w-[200px] h-[198px] lg:top-[457px] top-[450px] lg:left-[80px] -left-[20px]"
          src={homeImage1}
          alt=""
        />
      </div>
      <div>
        <img
          loading="lazy"
          className="absolute lg:w-max lg:h-max w-[100px] h-[170px] lg:top-[457px] top-[475px] lg:right-[80px] right-[0px]"
          src={bot}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[240px]"
          src={pattern1}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[400px] lg:right-0"
          src={pattern2}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[550px] lg:right-[450px]"
          src={pattern3}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[740px] lg:right-[595px]"
          src={pattern4}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[400px] lg:left-[350px]"
          src={pattern5}
          alt=""
        />
      </div>

      {/* Why Wasan section */}
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[1900px]"
          src={pattern1}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[1900px] lg:-right-0"
          src={pattern2}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[2400px] lg:right-0"
          src={pattern4}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[1970px] lg:left-[265px]"
          src={pattern5}
          alt=""
        />
      </div>
      <div>
        <img
          className="hidden lg:block lg:absolute lg:top-[2555px] lg:right-[480px] -rotate-[55deg]"
          src={pattern3}
          alt=""
        />
      </div>
    </div>
  );
};

export default Home;
