import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import Datepicker from "react-tailwindcss-datepicker";
import Swal from "sweetalert2";

import { BikeInsuranceFormContext } from "../../../pages/BikeInsurance/BikeInsuranceProposal";
import { createBikeProposal } from "../../../apis/bikeApis";

function VehicleDetails() {
  const isMobile = window.innerWidth <= 767;
  const navigate = useNavigate();
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    vehicleData,
    planData,
  } = useContext(BikeInsuranceFormContext);

  const formik = useFormik({
    initialValues: {
      enginenumber: formData.enginenumber,
      chasisnumber: formData.chasisnumber,
      puc: formData.puc,
      registrationDate: formData.registrationDate,
      policyEndDate: planData.nextPolicyEndDate,
      pdoc: "",
    },
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.empPANNO && userData.empEmail) {
      formik.setFieldValue("employeePanNumber", userData.empPANNO);
      formik.setFieldValue("employeeEmail", userData.empEmail);
    }
  }, []); // Empty dependency array to run this effect only once on mount

  const handleProposalSubmit = async (values) => {
    Swal.showLoading();
    const data = { ...formData, ...values };

    if (!data.policyEndDate) {
      Swal.fire({
        icon: "info",
        title: "Select Policy End Date",
      });
      return;
    }
    setFormData(data);

    const res = await createBikeProposal({
      ...data,
      policyEndDate: planData.nextPolicyEndDate,
      district: formData.state.toUpperCase(),
      INSURER_NAME: planData.INSURER_NAME,
      vehicleNo: formData.registrationnumber,
      manufactureDate: `${vehicleData.manufacturingYear}-${vehicleData.manufacturingMonth}-01`,
      planData: planData,
      enquiryId: planData.quoteId,
      IDV_of_Vehicle: planData.bikeValue ?? null,
      policyHolderType: "INDIVIDUAL",
      VoluntaryExcess: null,
      addOnPackagesAndCovers: planData.AddOnFilteredData ?? [],
      employeePanNumber: values.employeePanNumber,
      employeeEmail: values.employeeEmail,
    });
    Swal.hideLoading();
    if (res?.data?.policyExist) {
      console.log(res);
      Swal.fire({
        icon: "error",
        title: "Policy Exists !",
        text: res?.message,
        confirmButtonText: "Check Purchase Summary",
        confirmButtonColor: "#F8363B",
      }).then(() => {
        navigate("/bikeinsurance/purchasesummary", {
          state: {
            INSURER_NAME: "GODIGIT",
            policyId: res.data?.PolicyId,
          },
        });
      });
    } else if (res.ok) {
      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Proposal created successfully",
        confirmButtonText: "Ok",
        confirmButtonColor: "#F8363B",
      });
      navigate("../bikeproposalsummary", {
        state: {
          INSURER_NAME: "GODIGIT",
          policyId: res.data.policyDetails._id,
          proposalInfo: res.data,
        },
      });
    } else {
      console.log(res);
      Swal.fire({
        icon: "error",
        title: "Proposal creation failed",
        text: res?.message,
      });
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleProposalSubmit(formik.values);
      }}
      className="flex flex-col space-y-2"
    >
      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Engine Number</label>
          <input
            type="text"
            required
            name="enginenumber"
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 focus:outline-none bg-slate-100"
            placeholder="Enter Engine Number"
            value={formik.values.enginenumber}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.enginenumber && formik.errors.enginenumber ? (
              <div>{formik.errors.enginenumber}</div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Chassis Number</label>
          <input
            type="text"
            required
            name="chasisnumber"
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 focus:outline-none bg-slate-100"
            placeholder="Enter Chassis Number"
            value={formik.values.chasisnumber}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.chasisnumber && formik.errors.chasisnumber ? (
              <div>{formik.errors.chasisnumber}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">PUC Number</label>
          <input
            type="text"
            name="puc"
            required
            className="rounded-md border-2 lg:p-2 p-1 lg:w-96 w-full focus:outline-none bg-slate-100"
            placeholder="Enter PUC Number"
            value={formik.values.puc}
            onChange={formik.handleChange}
          />
          <div>
            {formik.touched.puc && formik.errors.puc ? (
              <div>{formik.errors.puc}</div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">Registration Date</label>
          <Datepicker
            popoverDirection={isMobile ? "up" : "down"}
            inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
            primaryColor={"rose"}
            useRange={false}
            asSingle={true}
            value={{
              startDate: formik.values.registrationDate,
              endDate: formik.values.registrationDate,
            }}
            onChange={(selectDate) => {
              formik.setFieldValue("registrationDate", selectDate.startDate);
            }}
          />
        </div>
      </div>

      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">
            Choose Policy End Date
          </label>
          <Datepicker
            popoverDirection="up"
            inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
            primaryColor={"rose"}
            useRange={false}
            asSingle={true}
            value={{
              startDate: formik.values.policyEndDate,
              endDate: formik.values.policyEndDate,
            }}
            minDate={new Date()}
            onChange={(selectDate) => {
              console.log(selectDate);
              formik.setFieldValue("policyEndDate", selectDate.startDate);
            }}
          />
        </div>
      </div>

      <div className="flex lg:flex-row lg:justify-between flex-col items-center lg:mb-4">
        <div className="lg:mb-0 mb-6 lg:mt-0 mt-4">
          <button
            type="button"
            onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
          >
            Previous
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default VehicleDetails;
