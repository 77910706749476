import React, { useContext } from "react";
import { BikeInsuranceFormContext } from "../../pages/BikeInsurance/BikeInsuranceProposal";
import Proposer from "./FormSteps/Proposer";
import Insured from "./FormSteps/Insured";
import AddressDetails from "./FormSteps/AddressDetails";
import VehicleDetails from "./FormSteps/VehicleDetails";

function Step({ shouldRenderVehicleDetails }) {
  const { activeStepIndex } = useContext(BikeInsuranceFormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Proposer />;
      break;
    case 1:
      stepContent = <Insured />;
      break;
    case 2:
      stepContent = <AddressDetails />;
      break;
    // case 3:
    //   stepContent = <VehicleDetails />;
    //   break;
    case 3:
      stepContent = shouldRenderVehicleDetails ? <VehicleDetails /> : null;
      break;
    default:
      break;
  }

  return (
    <div className="lg:p-8 p-6 border-2 bg-white rounded-2xl shadow-lg w-full">
      {stepContent}
    </div>
  );
}

export default Step;
