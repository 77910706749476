import React from "react";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";

//Router Layout
import RootLayout from "./layout/RootLayout";

//Pages
import BecomePosp from "./pages/BecomePosp";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import WasanLogin from "./pages/WasanLogin";
import Feedback from "./pages/Feedback";

// Car Insurance Pages
import CarInsuranceMng from "./pages/CarInsurance";
import CarInsurance from "./pages/CarInsurance/CarInsurance";
import CarInsuranceSummary from "./pages/CarInsurance/CarInsuranceSummary";
import CarInsuranceQuotes from "./pages/CarInsurance/CarInsuranceQuotes";
import CarInsuranceNewQuote from "./pages/CarInsurance/CarInsuranceNewQuote";
import CarInsuranceKYC from "./pages/CarInsurance/CarInsuranceKYC";
import CarInsuranceProposal from "./pages/CarInsurance/CarInsuranceProposal";
import CarProposalSummary from "./pages/CarInsurance/CarProposalSummary";
import CarRedirection from "./pages/CarInsurance/CarRedirection";
import CarPurchaseSummary from "./pages/CarInsurance/CarPurchaseSummary";

// Bike Insurance Pages
import BikeInsuranceMng from "./pages/BikeInsurance";
import BikeInsurance from "./pages/BikeInsurance/BikeInsurance";
import BikeInsuranceSummary from "./pages/BikeInsurance/BikeInsuranceSummary";
import BikeInsuranceQuotes from "./pages/BikeInsurance/BikeInsuranceQuotes";
import BikeInsuranceNewQuote from "./pages/BikeInsurance/BikeInsuranceNewQuote";
import BikeInsuranceKYC from "./pages/BikeInsurance/BikeInsuranceKYC";
import BikeInsuranceProposal from "./pages/BikeInsurance/BikeInsuranceProposal";
import BikeProposalSummary from "./pages/BikeInsurance/BikeProposalSummary";
import BikeRedirection from "./pages/BikeInsurance/BikeRedirection";
import BikePurchaseSummary from "./pages/BikeInsurance/BikePurchaseSummary";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/wasanlogin" element={<WasanLogin />} />

      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="becomeposp" element={<BecomePosp />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="faq" element={<Faq />} />
        <Route path="feedback" element={<Feedback />} />

        {/* Car Insurance Routes */}
        <Route path="carInsurance" element={<CarInsuranceMng />}>
          <Route path="" element={<CarInsurance />} />
          <Route path="summary" element={<CarInsuranceSummary />} />
          <Route path="quotes" element={<CarInsuranceQuotes />} />
          <Route path="newquotes" element={<CarInsuranceNewQuote />} />
          <Route path="kycstep" element={<CarInsuranceKYC />} />
          <Route path="proposal" element={<CarInsuranceProposal />} />
          <Route path="carproposalsummary" element={<CarProposalSummary />} />
          <Route path="redirect" element={<CarRedirection />} />
          <Route path="purchasesummary" element={<CarPurchaseSummary />} />
        </Route>

        {/* Bike Insurance Routes */}
        <Route path="bikeInsurance" element={<BikeInsuranceMng />}>
          <Route path="" element={<BikeInsurance />} />
          <Route path="summary" element={<BikeInsuranceSummary />} />
          <Route path="quotes" element={<BikeInsuranceQuotes />} />
          <Route path="newquotes" element={<BikeInsuranceNewQuote />} />
          <Route path="kycstep" element={<BikeInsuranceKYC />} />
          <Route path="proposal" element={<BikeInsuranceProposal />} />
          <Route path="bikeproposalsummary" element={<BikeProposalSummary />} />
          <Route path="redirect" element={<BikeRedirection />} />
          <Route path="purchasesummary" element={<BikePurchaseSummary />} />
        </Route>
      </Route>
    </>
  )
);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
