import React from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import Swal from "sweetalert2";

//apis and helper
import { getWithoutToken } from "../../helper/Http";
import apis from "../../helper/allApis";
import { bikeKycStatus } from "../../apis/bikeApis";

function BikeInsuranceKYC() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    INSURER_NAME,
    planData,
    vehicleInfo,
    vehicleNo,
    vehicleCode,
    vehicleMake,
    vehicleModel,
    vehicleVariant,
    insuranceType,
    ncbValue,
    madeClaim,
    toolbarOptions,
    previousPolicyType,
  } = location.state;

  const formik = useFormik({
    initialValues: {
      name: "",
      dob: "",
      mobileNumber: "",
      panNumber: "",
    },
  });

  const handleKycSubmit = async (values) => {
    const { name, dob, panNumber } = values;

    const res = await getWithoutToken(
      `${apis.KYC_DETAILS}?kycNo=${panNumber}&dob=${dob}&name=${name}`
    );
    if (res?.data?.status === false) {
      Swal.fire({
        icon: "error",
        title: res?.data?.message,
        text: res?.data?.data?.MessageResult?.ErrorMessage,
      });
    } else {
      Swal.fire({
        title: "Success",
        text: "KYC Verified :)",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("../proposal", {
          state: {
            INSURER_NAME,
            planData,
            vehicleInfo,
            vehicleNo,
            vehicleCode,
            vehicleMake,
            vehicleModel,
            vehicleVariant,
            insuranceType,
            ncbValue,
            madeClaim,
            toolbarOptions,
            previousPolicyType,
            kycInfo: {
              ...values,
              gender: res?.data?.data?.gender,
              email: res?.data?.data?.email,
            },
          },
        });
      });
    }
  };

  const handleHdfcKyc = async (values) => {
    const { dob, panNumber } = values;

    if (INSURER_NAME === "HDFC") {
      const res = await bikeKycStatus({
        INSURER_NAME,
        panNumber,
        dob,
      });
      console.log(res);
      if (res.ok) {
        console.log(res.data.kycLink);
        window.open(res.data.kycLink, "_blank");
        await handleKycSubmit(values);
      } else {
        Swal.fire({
          icon: "error",
          title: "Not able to fetched KYC link",
        });
      }
    }
  };

  return (
    <>
      <div className="bg-gradient-to-b from-[#faf5ea] to-transparent flex justify-center p-10">
        <div className="flex flex-col rounded-2xl bg-white shadow-lg lg:p-5 p-3 lg:w-max w-full">
          <p className="font-medium text-center lg:text-3xl text-xl text-[#9D0004] lg:mb-4 mb-2">
            KYC Details
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleKycSubmit(formik.values);
            }}
            className="flex items-center flex-col w-full"
          >
            <div className="flex flex-col items-start lg:w-full">
              <label className="font-medium text-[#00000094] lg:text-lg mb-2">
                Name as per ID
              </label>
              <input
                name="name"
                className="bg-slate-100 outline-none rounded-md p-1 border-2 lg:w-96 mb-2"
                placeholder="Enter Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="flex flex-col items-start lg:w-full">
              <label className="font-medium text-[#00000094] lg:text-lg mb-2">
                Mobile Number
              </label>
              <input
                name="mobileNumber"
                className="bg-slate-100 outline-none rounded-md p-1 border-2 lg:w-96 mb-2"
                placeholder="Enter Mobile Number"
                onChange={formik.handleChange}
                value={formik.values.mobileNumber}
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="text-red-500">{formik.errors.mobileNumber}</div>
              ) : null}
            </div>
            <div className="flex flex-col items-start lg:w-full">
              <label className="font-medium text-[#00000094] lg:text-lg mb-2">
                PAN Number
              </label>
              <input
                name="panNumber"
                className="bg-slate-100 outline-none rounded-md border-2 p-1 lg:w-96"
                placeholder="Enter PAN Number"
                onChange={formik.handleChange}
                value={formik.values.panNumber}
              />
              {formik.touched.panNumber && formik.errors.panNumber ? (
                <div className="text-red-500">{formik.errors.panNumber}</div>
              ) : null}
            </div>
            <div className="flex flex-col items-start lg:w-full">
              <label className="font-medium text-[#00000094] lg:text-lg mb-2">
                Date of Birth
              </label>
              {/* <input
                name="dob"
                className="bg-slate-100 outline-none rounded-md border-2 p-1 lg:w-96 w-[13rem] mb-2"
                type="date"
                placeholder="Enter Date of Birth"
                onChange={formik.handleChange}
                value={formik.values.dob}
              /> */}
              <Datepicker
                inputClassName="w-full border-2 mb-2 p-1 rounded-md bg-slate-100 outline-none"
                primaryColor={"rose"}
                useRange={false}
                asSingle={true}
                value={{
                  startDate: formik.values.dob,
                  endDate: formik.values.dob,
                }}
                maxDate={new Date()}
                onChange={(selectDate) => {
                  formik.setFieldValue("dob", selectDate.startDate);
                }}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div className="text-red-500">{formik.errors.dob}</div>
              ) : null}
            </div>

            {INSURER_NAME === "HDFC" ? (
              <button
                className="bg-[#9D0004] text-white font-medium rounded-xl lg:p-2 p-1 w-1/3 mt-5"
                onClick={handleHdfcKyc}
              >
                Go To {INSURER_NAME} Portal for KYC
              </button>
            ) : (
              <button
                className="bg-[#9D0004] text-white font-medium rounded-xl lg:p-2 p-1 w-1/3 mt-5"
                type="submit"
              >
                Next
              </button>
            )}
            {/* <button
              className="bg-[#9D0004] text-white font-medium rounded-xl lg:p-2 p-1 w-1/3 mt-5"
              type="submit"
            >
              Next
            </button> */}
          </form>
        </div>
      </div>
    </>
  );
}

export default BikeInsuranceKYC;
