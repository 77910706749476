import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

// assets and components
import contact from "../assets/contactImg.svg";
import Footer from "../components/Footer";
import config from "../config.js";

const ContactUs = () => {
  const isMobile = window.innerWidth <= 767;

  const form = useRef();

  const serviceId = config.SERVICE_ID;
  const templateId = config.TEMPLATE_ID;
  const publicKey = config.PUBLIC_KEY;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        alert("Email sent successfully", result.text);
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        alert("Something went wrong, please try again later..");
      }
    );
  };

  return (
    <>
      <section className="bg-gradient-to-t from-[#FFFFFF] to-[#FCDADB] flex lg:flex-row flex-col justify-around p-10">
        <article className="lg:w-2/5">
          <div>
            <h2 className="text-[#9D0004] font-medium text-2xl mb-2">
              Contact Us
            </h2>
            <div>
              <form
                ref={form}
                onSubmit={sendEmail}
                className="flex flex-col w-full"
              >
                <input
                  className=" bg-slate-50 outline-none border rounded-md p-2 mb-2"
                  placeholder="Name"
                  name="user_name"
                  // value={formData.name}
                  // onChange={handleChange}
                />
                <input
                  className=" bg-slate-50 outline-none border rounded-md p-2 mb-2 mt-2"
                  type="email"
                  placeholder="Email"
                  name="user_email"
                  // value={formData.email}
                  // onChange={handleChange}
                />
                <textarea
                  className=" bg-slate-50 outline-none border rounded-md p-2 mt-2 lg:h-[150px]"
                  placeholder="Message"
                  name="message"
                  // value={formData.message}
                  // onChange={handleChange}
                />
                <button className="bg-[#9D0004] p-2 mt-5 text-white rounded-md">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </article>
        <article className="lg:flex lg:flex-col">
          {!isMobile && (
            <div className="flex justify-center lg:mt-0 mt-10">
              <img loading="lazy" src={contact} alt="" />
            </div>
          )}
          <div className="mt-5">
            <h2 className="text-[#9D0004] font-medium text-2xl mb-2">
              Registered Address
            </h2>
            <p className="text-[#00000094]">
              Office No. 7, Ground Floor, Swastik Chambers, CST Road, Chembur,
              Mumbai-400071
            </p>

            <h2 className="text-[#9D0004] font-medium text-2xl mb-2 mt-2">
              Mumbai Address
            </h2>
            <p className="text-[#00000094]">
              Office No. 7, Ground Floor, Swastik Chambers, CST Road, Chembur,
              Mumbai-400071
            </p>
          </div>
          {isMobile ? (
            <div className="flex justify-center lg:mt-0 mt-10">
              <img loading="lazy" src={contact} alt="" />
            </div>
          ) : (
            <></>
          )}
        </article>
      </section>

      <Footer />
    </>
  );
};

export default ContactUs;
