import React from "react";
import scrolltotop from "../assets/scrolltotop.svg";

export default function ScrollToTop() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="m-5 mt-40 float-right">
      <button
        className="bg-white rounded-full shadow-lg w-14 h-14"
        onClick={scrollToTop}
      >
        <img src={scrolltotop} alt="" />
      </button>
    </div>
  );
}
