import { createSlice } from "@reduxjs/toolkit";
// import login from "../../apis/login";

// export const loginApiRequest = createAsyncThunk(
//    "auth/loginApiRequest",
//    async (credentials) => {
//       const response = await login(credentials);
//       return response.data; // Assuming the API response contains a 'data' property with the token
//    }
// );

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || null,
    isAuthenticated: localStorage.getItem("token") ? true : false,
    userId: null,
  },
  reducers: {
    loginRequest: (state, action) => {
      state.userId = action.payload.id;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.userId = null;
    },

    // ? Not required for now
    // extraReducers: (builder) => {
    //    builder
    //       .addCase(loginRequest.pending, (state) => {
    //          state.loading = true;
    //          state.error = null;
    //       })
    //       .addCase(loginRequest.fulfilled, (state, action) => {
    //          state.token = action.payload;
    //          state.isAuthenticated = true;
    //          state.loading = false;
    //          state.error = null;
    //          localStorage.setItem("token", action.payload);
    //       })
    //       .addCase(loginRequest.rejected, (state, action) => {
    //          state.loading = false;
    //          state.isAuthenticated = false;
    //          state.error = action.error.message;
    //       });
    // },
  },
});

// Action creators are generated for each case reducer function
export const { loginRequest, loginSuccess, loginFailure, logout } =
  authSlice.actions;

export default authSlice.reducer;
