import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getPolicyPdf } from "../../apis/verificationApis";
import Swal from "sweetalert2";

const SummaryItem = ({ label, value }) => (
  <div className="flex flex-row justify-between mt-2 mb-2">
    <p className="text-[#00000094] lg:text-lg">{label}</p>
    <p className="font-medium lg:text-lg">{value}</p>
  </div>
);

const BikePurchaseSummary = () => {
  let location = useLocation();
  if (!location.state?.policyId) {
    Swal.fire({
      icon: "error",
      title: "Policy Id Invalid",
    });
  }
  const { policyId, INSURER_NAME } = location.state;
  console.log(policyId, INSURER_NAME);
  const [pdfLink, setPdfLink] = useState();
  const [purchaseInfo, setPurchaseInfo] = useState({});

  useEffect(() => {
    const fetchPolicyPdf = async () => {
      Swal.showLoading();
      const res = await getPolicyPdf({
        policyId,
        INSURER_NAME,
      });
      Swal.hideLoading();
      console.log(res);
      if (res.ok) {
        console.log(res);
        setPdfLink(res.data.pdfLink);
        setPurchaseInfo(res.data.purchaseInfo);
      } else {
        Swal.fire({
          icon: "error",
          title: "Not able to fetched Policy Documents",
          text: res.message,
        });
      }
    };
    if (!policyId) {
      Swal.fire({
        icon: "error",
        title: "Policy Id Invalid",
      });
    } else {
      fetchPolicyPdf();
    }
  }, [policyId, INSURER_NAME]);

  const handleDownload = (e) => {
    e.preventDefault();
    window.open(pdfLink, "_blank");
  };
  return (
    <section>
      <article className="bg-gradient-to-b from-[#faf5ea] to-transparent">
        <h2 className="text-[#9D0004] font-medium lg:text-4xl text-2xl lg:p-8 p-4 text-center">
          Summary
        </h2>

        {/* <div className="bg-white shadow-lg rounded-lg border-2 w-2/5 p-4">
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">Bike Value</p>
            <p className="font-medium lg:text-lg">Rs 8,74,210</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">No Claim Bonus</p>
            <p className="font-medium lg:text-lg">20%</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">Plan Type</p>
            <p className="font-medium lg:text-lg">Comprehensive</p>
          </div>
          <div className="bg-[#FAF5EA] mt-4 mb-4 rounded-md flex justify-center items-center p-3">
            <p className="font-medium text-xl">Plan Breakup</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">Premium Amount</p>
            <p className="font-medium lg:text-lg">Rs 14,740</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">GST</p>
            <p className="font-medium lg:text-lg">Rs 874</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#00000094] lg:text-lg">Your Total</p>
            <p className="font-medium lg:text-lg">Rs 14,988</p>
          </div>
        </div> */}

        <div className="flex justify-center items-center">
          <div className="bg-white shadow-lg rounded-lg border-2 lg:w-2/5 w-4/5 p-4">
            {/* <SummaryItem label="Bike Value" value="Rs 8,74,210" /> */}
            <SummaryItem
              label="Bike Value"
              value={"Rs " + purchaseInfo?.IDV_Cover}
            />
            <SummaryItem label="No Claim Bonus" value="20%" />
            <SummaryItem label="Plan Type" value="Comprehensive" />

            <div className="bg-[#FAF5EA] mt-4 mb-4 rounded-md flex justify-center items-center lg:p-3 p-2">
              <p className="font-medium text-lg lg:text-xl">Plan Breakup</p>
            </div>

            {/* <SummaryItem label="Premium Amount" value="Rs 14,740" />
            <SummaryItem label="GST" value="Rs 874" />
            <SummaryItem label="Your Total" value="Rs 14,988" /> */}
            <SummaryItem
              label="Premium Amount"
              value={"Rs " + purchaseInfo?.TotalPremium}
            />
            <SummaryItem label="GST" value={"Rs " + purchaseInfo?.TotalTax} />
            <SummaryItem
              label="Your Total"
              value={"Rs " + purchaseInfo?.TotalAmount}
            />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col lg:justify-evenly items-center lg:p-10 p-5 m-5">
          {window.innerWidth >= 640 && (
            <div>
              <Link className="text-[#9D0004] font-medium text-lg">
                Go Back to Home
              </Link>
            </div>
          )}
          <div>
            <button
              onClick={handleDownload}
              className="bg-[#9D0004] text-white font-medium text-base lg:text-lg p-3 rounded-lg"
            >
              Download Insurance Bond
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default BikePurchaseSummary;
